<template>
	<ion-header>
		<ion-toolbar :color="online ? 'branding' : 'dark'">
			<ion-title slot="start" style="padding: 0px 40px">{{ modalTitle }}</ion-title>
		</ion-toolbar>
	</ion-header>

	<ion-content style="--overflow: unset">
		<!-- FIXME: Don't do this! -->
		<!-- eslint-disable-next-line vue/no-mutating-props -->
		<DateInput
			:date="date"
			@update:date="updateDate"
			:autoFocus="true"
			:required="required"
			:minDate="minDate"
			:maxDate="maxDate"
		></DateInput>
		<div style="display: flex">
			<ion-button @click="dismiss" color="light" expand="block" style="flex-grow: 1">
				{{ $t("common.cancel") }}
			</ion-button>
			<ion-button
				@click="submit(date)"
				expand="block"
				type="submit"
				:disabled="required && !date"
				style="flex-grow: 1"
			>
				{{ $t("common.update") }}
			</ion-button>
		</div>
	</ion-content>
</template>

<style>
	.date-modal {
		--height: 162px;
		/* 1. Fix stacked modal backdrop issue when modal size varies
            (https://ionicframework.com/docs/v5/api/modal#customization) */
		--backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
	}

	.date-modal > .modal-wrapper {
		border-radius: 6px;
		/* (see 1.) */
		--box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
	}

	.date-modal ion-content {
		--padding-start: 10px;
		--padding-end: 10px;
		--padding-top: 8px;
	}
</style>

<script lang="ts">
	import { mapState } from "vuex";
	import { defineAsyncComponent, defineComponent } from "vue";
	import DateInput from "@/components/DateInput.vue";
	import { IonTitle, IonButton, IonToolbar, IonHeader, IonContent, modalController } from "@ionic/vue";
	import $ from "jquery";
	import { dateFormat } from "@/utils";

	export default defineComponent({
		name: "DateModal",
		components: {
			// Ionic Components
			IonTitle,
			IonButton,
			IonToolbar,
			IonHeader,
			IonContent,
			// Custom Components
			DateInput,
		},
		props: {
			submit: { type: Function, default: () => {} },
			initialDate: { type: String },
			title: { type: String },
			required: { type: Boolean, default: false },
			minDate: { type: Date, default: null },
			maxDate: { type: Date, default: null },
		},
		data() {
			return {
				date: this.initialDate,
				modalTitle: this.title ?? this.$t("time.date"),
			};
		},
		methods: {
			updateDate(val: string) {
				this.date = val;
			},
			async dismiss() {
				await modalController.dismiss(this);
			},
		},
		unmounted() {
			$(".flatpickr-calendar").remove();
		},
		computed: {
			...mapState(["online"]),
		},
	});

	/**
	 *
	 * @param opts
	 *  - required (bool): false by default, disable "submit" button for empty values
	 *  - callback (fn(value)): on submit, call this function with the selected value
	 *      (str: yyyy-MM-dd) as the first argument
	 *  - title (string): 'Date' by default, modal title
	 *  - value (string yyyy-MM-dd): optional date string for initial value
	 *  - minDate (date): optional min date for the date picker
	 *  - maxDate (date): optional max date for the date picker
	 */
	export function editDate(opts: any) {
		modalController
			.create({
				component: defineAsyncComponent(() => import("@/views/Modal/Date.vue")),
				cssClass: "date-modal",
				componentProps: {
					submit: (selectedDate: string) => {
						modalController.dismiss();
						opts.callback(selectedDate);
					},
					initialDate: opts.value && dateFormat(new Date(opts.value), "yyyy-MM-dd", true),
					title: opts.title,
					required: opts.required,
					minDate: opts.minDate,
					maxDate: opts.maxDate,
				},
			})
			.then((m) => m.present());
	}
</script>
