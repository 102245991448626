import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_DateInput = _resolveComponent("DateInput")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, {
          color: _ctx.online ? 'branding' : 'dark'
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, {
              slot: "start",
              style: {"padding":"0px 40px"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modalTitle), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["color"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { style: {"--overflow":"unset"} }, {
      default: _withCtx(() => [
        _createVNode(_component_DateInput, {
          date: _ctx.date,
          "onUpdate:date": _ctx.updateDate,
          autoFocus: true,
          required: _ctx.required,
          minDate: _ctx.minDate,
          maxDate: _ctx.maxDate
        }, null, 8, ["date", "onUpdate:date", "required", "minDate", "maxDate"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_button, {
            onClick: _ctx.dismiss,
            color: "light",
            expand: "block",
            style: {"flex-grow":"1"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("common.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_ion_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submit(_ctx.date))),
            expand: "block",
            type: "submit",
            disabled: _ctx.required && !_ctx.date,
            style: {"flex-grow":"1"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("common.update")), 1)
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ]),
      _: 1
    })
  ], 64))
}